.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  margin-bottom: 2rem;
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.empty-state {
  background: white;
  padding: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 2rem;
}

.empty-state-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #f2d13e;
}

.empty-state-text {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2rem;
}

.add-question-button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-question-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mobile-add-button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  width: 36px;
  height: 36px;
  font-size: 1.5rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobile-add-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.sort-section {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.sort-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.sort-label {
  color: #666;
  font-weight: 500;
}

.sort-select {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  background-color: white;
  color: #333;
  font-size: 1rem;
  cursor: pointer;
  flex: 1;
}

.sort-select:hover {
  border-color: #f2d13e;
}

.table-container {
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: #f8f9fa;
  color: #333;
  font-weight: 600;
  text-align: left;
  padding: 1rem;
  border-bottom: 2px solid #f2d13e;
}

.table-row {
  border-bottom: 1px solid #eee;
}

.table-row:hover {
  background-color: #f8f9fa;
}

.table-cell {
  padding: 1rem;
  color: #666;
  vertical-align: middle;
}

.question-cell {
  font-weight: 500;
  color: #333;
}

.action-buttons-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: relative;
}

.action-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  min-width: 80px;
  text-align: center;
}

.add-to-button {
  background-color: #198754;
}

.edit-button {
  background-color: #ffc107;
  color: black;
}

.delete-button {
  background-color: #dc3545;
}

.action-button:hover {
  opacity: 0.9;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  min-width: 150px;
}

.dropdown-button {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  color: #333;
  display: block;
}

.dropdown-button:hover {
  background-color: #f8f9fa;
}

.dropdown-item {
  padding: 0.75rem 1rem;
  color: #666;
  display: block;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
}

.pagination-button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background: white;
  cursor: pointer;
  color: #333;
}

.pagination-button:hover:not(:disabled) {
  background-color: #f8f9fa;
  border-color: #f2d13e;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-button.hidden {
  visibility: hidden;
}

.page-numbers {
  display: flex;
  gap: 0.5rem;
}

.page-number {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  background: white;
  cursor: pointer;
  color: #333;
}

.page-number:hover {
  background-color: #f8f9fa;
  border-color: #f2d13e;
}

.page-number.active {
  background-color: #f2d13e;
  border-color: #f2d13e;
  color: white;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .header {
    padding: 1rem;
  }

  .header h1 {
    font-size: 1.5rem;
  }

  .sort-section {
    padding: 1rem;
  }

  .sort-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .sort-select {
    width: 100%;
  }

  .table-container {
    margin: 1rem 0;
  }

  .table {
    width: 100%;
    min-width: auto;
  }

  .action-buttons-container {
    flex-wrap: nowrap;
  }
} 