/* button hover amazing effect */
.camera-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  padding: 0;
  box-sizing: border-box;
  min-height: calc(100vh - 60px);

  .confirm-values-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
  }

  &.final-submission {
    .table-container-wrapper {
      margin-top: 20px;
    }

    .table-container {
      max-width: 800px;
      margin: 0 auto;
      padding: 20px;
      background-color: #f8f9fa;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .confirm-values-title {
      margin-top: 20px;
    }

    // Hide all other elements except the table and feedback
    > *:not(.table-container-wrapper) {
      display: none;
    }
  }
}

.camera-border {
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid #000; /* Optional: Add a border for better visibility */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.webcam-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Ensure the image does not overflow the container */
}

.webcam-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without stretching */
}

.webcam {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the webcam video covers the entire container */
}

.camera-border::before,
.camera-border::after,
.camera-border span::before,
.camera-border span::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  background: transparent;
  transition: 1s;
  color: blue;
  /* -webkit-mix-blend-mode: hue; */
}

.camera-border::before {
  top: -2px;
  left: -2px;
  border-top: 5px solid #0e93cd;
  border-left: 5px solid #0e93cd;
}
.camera-border::after {
  top: -2px;
  right: -2px;
  border-top: 5px solid #0e93cd;
  border-right: 5px solid #0e93cd;
}

.camera-border span::before {
  bottom: -2px;
  left: -2px;
  border-bottom: 5px solid #0e93cd;
  border-left: 5px solid #0e93cd;
}
.camera-border span::after {
  bottom: -2px;
  right: -2px;
  border-bottom: 5px solid #0e93cd;
  border-right: 5px solid #0e93cd;
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.green-button {
  background-color: green;
  color: white;
}

.topic-practical-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap; /* Prevent wrapping */
  width: 100%; /* Ensure it takes full width */
  padding: 0 10px; /* Add some padding for mobile */
}

.topic-practical-text {
  flex: 1;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis if text overflows */
}

.change-button {
  margin-left: 10px; /* Adjust as needed */
  flex-shrink: 0; /* Prevent button from shrinking */
  font-size: 12px; /* Make the button text smaller */
  padding: 5px 10px; /* Adjust padding for a smaller button */
}

.upload-instructions {
  text-align: center;
  margin: 20px 0;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.camera-border {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.webcam-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
  padding: 20px;
  width: 100%;
  max-width: 300px;
  height: 300px;
}

.submission-text {
  text-align: center;
  margin: 20px 0;
}

.received-data {
  margin-top: 20px;
}

.table-container-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  padding-top: 0;
}

.table-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
}

.feedback-carousel-container {
  max-width: 100%;
  overflow-x: hidden;
  margin-bottom: 20px; /* Add some space below the carousel */
}

.feedback-item {
  padding: 10px;
  
  h3 {
    margin-bottom: 10px;
    text-align: center; /* Center the feedback title */
  }
  
  p {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: center; /* Center the feedback text */
    max-height: 150px;
    overflow-y: auto;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    border: 2px solid red; /* Add a red border */
    margin: 0 10px; /* Add some horizontal margin */
  }
}

@media (max-width: 768px) {
  .feedback-item p {
    font-size: 14px;
  }
}

.feedback-and-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

.pencil-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 400px;
  margin: 20px 0;
}

.pencil {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.pencil-progress-container {
  position: absolute;
  top: 43%;
  left: 13%; // Adjust this to match the start of the pencil lead
  width: 79%; // Adjust this to match the length of the pencil lead
  height: 40px;
  background-color: transparent;
}

.pencil-progress {
  height: 100%;
  background-color: rgba(52, 152, 219, 0.5);
  transition: width 0.1s linear;
}

.pencil-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  font-weight: bold;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.submissions-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999; /* Ensure it is below the navbar */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevent scrolling */
  background-color: rgba(0, 0, 0, 0.8);
}

.submissions-modal-content {
  position: relative;
  background-color: transparent; /* Make the background transparent */
  padding: 0; /* Remove padding */
  border: none; /* Remove border */
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.submissions-close-button {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px; /* Make the button a bit bigger */
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* Increase font size */
  line-height: 1;
  width: auto; /* Ensure the button does not stretch */
}

.submissions-close-button:hover,
.submissions-close-button:focus {
  background-color: #d32f2f;
}

.submissions-modal-image {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  object-fit: contain; /* Ensure the image scales properly */
}

.feedback-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.camera-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 100%;
  overflow-x: hidden;
}

.content-wrapper {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.confirm-values-title {
  text-align: center;
  margin-bottom: 15px;
}

.table-content {
  width: 100%;
  overflow-x: auto;
}

.loading-screen {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.feedback-container {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-wrapper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.feedback-item {
  padding: 10px;
  
  h3 {
    margin-bottom: 10px;
  }
  
  p {
    font-size: 14px;
    line-height: 1.4;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

@media (max-width: 768px) {
  .carousel-wrapper {
    max-width: 300px;
  }

  .feedback-item {
    p {
      font-size: 12px;
    }
  }
}

/* Adjust the Carousel arrows to be more visible */
.carousel .control-arrow {
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff !important;
}

.camera-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.table-container-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

.table-container {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.confirm-values-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

// Ensure table fits within container
table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
}

td, th {
  padding: 8px;
  border: 1px solid #ddd;
  word-break: break-word;
}

// Responsive adjustments
@media (max-width: 768px) {
  .camera-container {
    padding: 10px;
  }

  .table-container {
    padding: 5px;
  }

  .confirm-values-title {
    font-size: 20px;
  }

  td, th {
    padding: 5px;
    font-size: 14px;
  }
}