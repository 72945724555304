.class-details-container {
  width: calc(100vw - 250px); /* Full viewport width minus sidebar */
  margin-left: 250px;
  padding: 2rem;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.content-section {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  width: 100%;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f2d13e;
}

.section-header h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.section-header h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.table-container {
  width: 100%;
  margin-top: 1rem;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th {
  background-color: #f8f9fa;
  padding: 1rem;
  font-weight: 600;
  color: #333;
  text-align: left;
  border-bottom: 2px solid #f2d13e;
  white-space: nowrap;
}

.table-container td {
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  vertical-align: middle;
  max-width: 300px;
  word-wrap: break-word;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.action-buttons {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: flex-end;
}

.primary-button {
  background-color: #f2d13e;
  color: #000;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s;
  min-width: fit-content;
  white-space: nowrap;
}

.primary-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.primary-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.secondary-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s;
  min-width: fit-content;
  white-space: nowrap;
}

.secondary-button:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
}

.danger-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s;
  min-width: 140px;
  white-space: nowrap;
}

.danger-button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.icon-button {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 4px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  background-color: #dc354520;
}

.icon-button svg {
  width: 1rem;
  height: 1rem;
}

.empty-state {
  text-align: center;
  padding: 3rem 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #6c757d;
}

.empty-state p {
  margin-bottom: 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.modal-content {
  padding: 1.5rem;
}

.students-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  max-height: 300px;
  overflow-y: auto;
}

.student-item {
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.student-item:hover {
  background-color: #f8f9fa;
  border-color: #f2d13e;
}

.student-item.selected {
  background-color: #f2d13e20;
  border-color: #f2d13e;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1.5rem;
  border-top: 1px solid #dee2e6;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #6c757d;
}

.error {
  text-align: center;
  padding: 2rem;
  color: #dc3545;
  background-color: #f8d7da;
  border-radius: 8px;
  margin: 1rem 0;
}

/* Questions table specific styles */
.questions-table th:first-child {
  width: 50%;
}

.questions-table th:nth-child(2),
.questions-table th:nth-child(3) {
  width: 20%;
}

.questions-table th:last-child {
  width: 10%;
}

.questions-table td {
  vertical-align: middle;
}

@media (max-width: 768px) {
  .class-details-container {
    width: 100%;
    margin-left: 0;
    padding: 1rem;
  }

  .section-header {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }

  .action-buttons {
    justify-content: center;
  }

  .table-container {
    margin: 0 -1rem;
    width: calc(100% + 2rem);
  }
} 