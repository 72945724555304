.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;

  .popup-content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 400px;
    position: relative;
  }

  .popup-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 600;
      color: #2c3e50;
    }

    .close-button {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      font-size: 1.5rem;
      color: #6c757d;
      cursor: pointer;
      padding: 0;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        color: #2c3e50;
      }
    }
  }

  .popup-body {
    padding: 1.5rem;

    p {
      margin: 0 0 1rem;
      color: #4a5568;
    }

    .info-group {
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        color: #2c3e50;
        font-weight: 600;
      }
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
      margin-top: 1.5rem;

      button {
        padding: 0.5rem 1rem;
        border-radius: 6px;
        border: none;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;

        &.cancel-btn {
          background-color: #e2e8f0;
          color: #4a5568;

          &:hover {
            background-color: #cbd5e0;
          }
        }

        &.confirm-btn {
          color: white;

          &.delete {
            background-color: #dc3545;
            
            &:hover {
              background-color: #c82333;
            }
          }

          &.promote {
            background-color: #321E3C;
            
            &:hover {
              background-color: #251530;
            }
          }
        }
      }
    }
  }
} 