.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  margin-bottom: 2rem;
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.add-class-button {
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.add-class-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.empty-state {
  background: white;
  padding: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 2rem;
}

.empty-state-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #f2d13e;
}

.empty-state-text {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2rem;
}

.error-state {
  background: #fff3f3;
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
  margin-top: 2rem;
}

.error-message {
  color: #dc3545;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.classes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
}

.class-card {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.class-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-color: #f2d13e;
}

.class-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
}

.class-stats {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  text-align: center;
}

.stat-item {
  padding: 0.75rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
}

.stat-label {
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 0.25rem;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .header {
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
  }

  .header h1 {
    font-size: 1.5rem;
  }

  .add-class-button {
    width: 100%;
  }

  .classes-grid {
    grid-template-columns: 1fr;
  }

  .class-card {
    margin-bottom: 1rem;
  }
} 