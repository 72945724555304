.my-classes-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  margin-top: 80px;
  margin-left: 250px;
  width: calc(100% - 250px);
}

.classes-header {
  margin-bottom: 2rem;
}

.classes-header h1 {
  font-size: 2rem;
  color: #333;
  margin: 0;
}

.classes-content {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.empty-state {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.empty-state p {
  font-size: 1rem;
  margin: 0;
}

.classes-grid {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.class-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid #e9ecef;
  cursor: pointer;
  transition: all 0.3s ease;
}

.class-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.class-card h2 {
  font-size: 1.25rem;
  color: #333;
  margin: 0 0 1rem 0;
}

.class-info {
  margin-top: 0.5rem;
}

.class-info p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
}

.info-label {
  font-weight: 500;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  font-size: 1rem;
  color: #666;
}

.error {
  color: #dc3545;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .my-classes-container {
    margin: 60px 0 0 0;
    width: 100%;
    padding: 1rem;
  }

  .classes-header {
    margin-bottom: 1rem;
  }

  .classes-header h1 {
    font-size: 1.5rem;
  }

  .classes-content {
    padding: 1rem;
  }

  .classes-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .class-card {
    padding: 1rem;
  }

  .class-card h2 {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  .class-info p {
    font-size: 0.9rem;
  }
} 