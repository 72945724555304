.all-submissions-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}

.submissions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.submissions-header h1 {
  font-size: 1.8rem;
  color: #333;
  margin: 0;
}

.total-count {
  color: #666;
  font-size: 1rem;
  margin: 0;
}

.table-container {
  overflow-x: auto;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.submissions-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.submissions-table th,
.submissions-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.submissions-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #333;
}

.submissions-table tr:last-child td {
  border-bottom: none;
}

.submissions-table tbody tr:hover {
  background-color: #f8f9fa;
}

.empty-state {
  text-align: center;
  padding: 3rem;
  color: #666;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #dc3545;
}

@media (max-width: 768px) {
  .all-submissions-container {
    padding: 1rem;
  }

  .submissions-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .submissions-header h1 {
    font-size: 1.5rem;
  }
} 