.teacher-page {
  padding-top: 60px; // Adjust this value based on your navbar height
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fadeInLeft 1s forwards;
  animation-delay: 1s;
  .header {
    margin: 20px 0px;
    color: #0e93cd;
  }
}