.settings-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;

  .settings-content {
    background: white;
    border-radius: 12px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    width: 350px;
    position: relative;
  }

  .settings-header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    h2 {
      margin: 0;
      font-size: 1.25rem;
      font-weight: 600;
      color: #2c3e50;
    }

    .close-button {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      font-size: 1.5rem;
      color: #6c757d;
      cursor: pointer;
      padding: 0;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        color: #2c3e50;
      }
    }
  }

  .settings-body {
    padding: 1.5rem;
  }

  .school-logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;

    .school-logo {
      width: 120px;
      height: auto;
      object-fit: contain;
    }
  }

  .user-info {
    .info-group {
      margin-bottom: 1.25rem;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        display: block;
        font-size: 0.875rem;
        color: #6c757d;
        margin-bottom: 0.5rem;
        font-weight: 500;
        text-align: center;
      }

      p {
        font-size: 1rem;
        color: #2c3e50;
        margin: 0;
        padding: 0.5rem 0;
        border-bottom: 1px solid #eee;
        text-align: center;

        &.role-badge {
          display: inline-block;
          padding: 0.35rem 1.5rem;
          border-radius: 16px;
          color: white;
          font-weight: 500;
          border: none;
          min-width: 100px;
        }
      }
    }
  }
} 