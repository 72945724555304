.all-classes-container {
  padding: 1.5rem;
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.page-header {
  background: white;
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.75rem;
}

.page-header h1 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
}

.total-count {
  color: #666;
  margin: 0.25rem 0 0;
  font-size: 0.85rem;
}

.controls-container {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.search-input {
  flex: 2;
  padding: 0.4rem 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 0.85rem;
}

.filter-select {
  flex: 1;
  padding: 0.4rem 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 0.85rem;
  background-color: white;
}

.classes-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, minmax(100px, 120px));
  gap: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.class-card {
  background: white;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.class-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.class-card h3 {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
  font-weight: 700;
  color: #000000 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
  min-height: 1.2em;
  display: block;
}

.class-card p {
  margin: 0.2rem 0;
  color: #666;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2;
}

.class-card-content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: 100%;
  color: #000000;
  z-index: 1;
}

.empty-state {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.empty-state p {
  color: #666;
  font-size: 1rem;
  margin: 0;
}

.pagination-container {
  display: flex;
  justify-content: center;
  gap: 0.75rem;
  padding: 0.75rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  background: transparent;
}

.pagination-button {
  padding: 0.5rem 1rem;
  border: 1px solid #e6c52e;
  background: white;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.2s;
  color: #333;
  min-width: 45px;
  font-weight: 500;
  font-size: 1.1rem;
}

.pagination-button:hover:not(:disabled) {
  background: rgba(230, 197, 46, 0.1);
}

.pagination-button.active {
  background: #e6c52e;
  color: #000;
  border-color: #e6c52e;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #ddd;
}

@media (max-width: 1200px) {
  .classes-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .class-card h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .classes-grid {
    grid-template-columns: 1fr;
  }
  
  .class-card h3 {
    font-size: 1.1rem;
  }
  
  .pagination-container {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .pagination-button {
    padding: 0.4rem 0.75rem;
    font-size: 1rem;
  }
  
  .controls-container {
    flex-direction: column;
  }
  
  .search-input,
  .filter-select {
    width: 100%;
  }
}

.class-stats {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.class-stats p {
  color: #666;
  font-size: 0.9rem;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #dc3545;
}

.create-button {
  background-color: #f2d13e;
  color: #000;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.create-button:hover {
  background-color: #e6c52e;
  transform: translateY(-2px);
}

.create-button:active {
  transform: translateY(0);
} 