.rubric-list-page {
    text-align: center;
    padding: 20px;
  
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    ul {
      list-style: none;
      padding: 0;
  
      li {
        padding: 15px;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #f8f9fa;
  
        h2 {
          margin: 0;
          font-size: 18px;
          color: #007bff;
        }
  
        p {
          margin: 5px 0 0;
          font-size: 14px;
          color: #555;
        }
      }
    }
  }
  