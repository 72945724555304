.container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  margin-bottom: 2rem;
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.steps-indicator {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
  gap: 4rem;
  padding: 0 1rem;
}

.step {
  display: flex;
  align-items: center;
  color: #666;
  font-weight: 500;
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid transparent;
}

.step.active {
  color: #f2d13e;
  border-bottom-color: #f2d13e;
}

.form-container {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step-content {
  max-width: 800px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  transition: border-color 0.2s;
}

.form-group input:focus {
  outline: none;
  border-color: #f2d13e;
}

.error-message {
  background: #fff3f3;
  color: #dc3545;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
}

.students-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  max-height: 500px;
  overflow-y: auto;
  padding: 0.5rem;
  margin: -0.5rem;
}

.student-item {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;
}

.student-item:hover {
  background: #fff;
  border-color: #f2d13e;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.student-item.selected {
  background: #fff;
  border-color: #f2d13e;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.checkbox-label {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  accent-color: #f2d13e;
  margin-top: 0.25rem;
}

.student-name {
  font-weight: 500;
  color: #333;
  margin-bottom: 0.25rem;
}

.student-email {
  color: #666;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.student-school {
  color: #666;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background: #f2d13e20;
  border-radius: 0.25rem;
  display: inline-block;
}

.no-students {
  grid-column: 1 / -1;
  text-align: center;
  padding: 2rem;
  color: #666;
  background: #f8f9fa;
  border-radius: 0.5rem;
  font-style: italic;
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
  align-items: center;
}

.cancel-button,
.back-button,
.next-button,
.submit-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  background: #f8f9fa;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 120px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.next-button,
.submit-button {
  background: #f2d13e;
  color: #000;
}

.next-button:disabled {
  background: #666;
  color: white;
}

.next-button:hover:not(:disabled),
.submit-button:hover,
.cancel-button:hover,
.back-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.next-button:disabled,
.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .header {
    padding: 1rem;
  }

  .header h1 {
    font-size: 1.5rem;
  }

  .form-container {
    padding: 1rem;
  }

  .students-list {
    grid-template-columns: 1fr;
  }

  .button-group {
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
  }

  .cancel-button,
  .back-button,
  .next-button,
  .submit-button {
    width: 160px;
  }
} 