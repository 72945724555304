.set-high-mark-page {
    text-align: center;
    padding: 20px;
  
    h1 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    textarea {
      width: 80%;
      height: 150px;
      padding: 10px;
      margin-bottom: 20px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 16px;
    }
  
    .buttons {
      display: flex;
      justify-content: center;
      gap: 15px;
  
      button {
        padding: 10px 20px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  