.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.popup-header {
  padding: 1.5rem;
  border-bottom: 2px solid #f2d13e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 1;
}

.popup-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.2s;
}

.close-button:hover {
  color: #333;
}

.popup-content {
  flex: 1;
  overflow: hidden;
}

.help-container {
  display: flex;
  height: 100%;
  max-height: calc(90vh - 80px);
}

.help-sidebar {
  width: 250px;
  background: #f8f9fa;
  padding: 1rem;
  border-right: 1px solid #dee2e6;
  overflow-y: auto;
}

.help-section-btn {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  background: none;
  border: none;
  border-radius: 4px;
  color: #666;
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.help-section-btn:hover {
  background: #f2d13e20;
  color: #333;
}

.help-section-btn.active {
  background: #f2d13e;
  color: #000;
  font-weight: 500;
}

.help-main {
  flex: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

.help-main h3 {
  font-size: 1.25rem;
  margin: 0 0 1.5rem 0;
  color: #333;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #eee;
}

.help-main ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.help-main li {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
  line-height: 1.5;
  color: #444;
}

.help-main li::before {
  content: "•";
  color: #f2d13e;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
  top: -1px;
}

@media (max-width: 768px) {
  .popup {
    width: 95%;
    height: 95vh;
    margin: 0;
  }

  .help-container {
    flex-direction: column;
    max-height: calc(95vh - 80px);
  }

  .help-sidebar {
    width: 100%;
    max-height: 200px;
    border-right: none;
    border-bottom: 1px solid #dee2e6;
  }

  .help-main {
    padding: 1rem;
  }
} 