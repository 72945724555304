.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.custom-button-default {
  padding: 15px;
  font-family: "Courier New", Courier, monospace;
  font-size: large;
  background-color: #4069e5;
  overflow: none;
  border: #4069e5;
  color: white;
  border-radius: 3px;
  transition: 0.5s all;
}

.custom-button-default:hover {
  background-color: whitesmoke;
  color: #4069e5;
  cursor: pointer;
  border: 1px solid #4069e5;
}

.otherTypes {
  width: 180px;
  height: 40px;
  font-family: "Courier New", Courier, monospace;
  font-size: large;
  background-color: powderblue;
  color: black;
  border-radius: 3px;
  transition: 0.5s all;
}
.otherTypes:hover {
  background-color: whitesmoke;
  color: powderblue;
  cursor: pointer;
  border: 1px solid black;
}
