.submissions-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  margin-bottom: 2rem;
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.header h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.filters {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.search-container {
  position: relative;
}

.search-input {
  padding: 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  width: 300px;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #f2d13e;
  box-shadow: 0 0 0 2px rgba(242, 209, 62, 0.2);
}

.sort-select {
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  min-width: 160px;
  transition: all 0.3s ease;
}

.sort-select:focus {
  outline: none;
  border-color: #f2d13e;
  box-shadow: 0 0 0 2px rgba(242, 209, 62, 0.2);
}

.submissions-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.submission-card {
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.submission-card:hover {
  border-color: #f2d13e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submission-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.submission-info {
  flex: 1;
}

.submission-header {
  margin-bottom: 0.5rem;
}

.submission-header h3 {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin: 0 0 0.25rem 0;
}

.student-name {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
}

.submission-date {
  color: #666;
  font-size: 0.875rem;
}

.submission-score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
}

.submission-score h4 {
  margin: 0;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
}

.questions-count {
  color: #666;
  font-size: 0.875rem;
}

.view-button {
  padding: 0.5rem 1rem;
  background-color: #f2d13e;
  color: #000;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.3s ease;
  white-space: nowrap;
  min-width: 100px;
  align-self: center;
}

.view-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.empty-state {
  background: white;
  padding: 3rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-top: 2rem;
}

.empty-state p {
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2rem;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #666;
}

.error {
  color: #dc3545;
  background: #fff3f3;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-header {
  position: sticky;
  top: 0;
  background: white;
  padding: 1.5rem;
  border-bottom: 2px solid #f2d13e;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

.popup-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
}

.popup-content {
  padding: 1.5rem;
}

.details-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.detail-row {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background-color: #f8f9fa;
}

.detail-label {
  font-weight: 600;
  color: #666;
  margin-bottom: 0.5rem;
}

.detail-value {
  color: #333;
  width: 100%;
  word-break: break-word;
}

.detail-value.answer {
  white-space: pre-wrap;
  padding: 0.75rem;
  background: white;
  border-radius: 0.5rem;
  border: 1px solid #dee2e6;
  margin-top: 0.5rem;
  font-family: inherit;
  line-height: 1.5;
}

.questions-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 1rem;
}

.question-container {
  border: 1px solid #dee2e6;
  border-radius: 0.5rem;
  overflow: hidden;
  background: white;
}

.question-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #f8f9fa;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.question-header:hover {
  background: #f2d13e20;
}

.question-header.expanded {
  background: #f2d13e20;
  border-bottom: 1px solid #dee2e6;
}

.question-title {
  font-weight: 600;
  color: #333;
  font-size: 1rem;
}

.question-summary {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-shrink: 0;
}

.score-badge {
  background: #f2d13e;
  color: #000;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

.expand-icon {
  font-size: 1.25rem;
  color: #666;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-details {
  padding: 1rem;
  background: white;
}

.submission-info-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

.detail-row.compact {
  flex: 1;
  min-width: 0;
}

.detail-row.compact .detail-label {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.detail-row.compact .detail-value {
  font-size: 0.95rem;
}

@media (max-width: 768px) {
  .submissions-container {
    padding: 1rem;
  }

  .header {
    padding: 1rem;
    flex-direction: column;
    align-items: stretch;
  }

  .header h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .filters {
    flex-direction: column;
    gap: 0.75rem;
  }

  .search-input {
    width: 100%;
  }

  .sort-select {
    width: 100%;
  }

  .submissions-list {
    grid-template-columns: 1fr;
  }

  .popup {
    width: 95%;
    height: 95vh;
    margin: 0;
    max-width: none;
  }

  .popup-content {
    padding: 1rem;
  }

  .question-header {
    padding: 0.75rem;
  }

  .question-title {
    font-size: 0.9rem;
  }

  .score-badge {
    font-size: 0.8rem;
    padding: 0.2rem 0.5rem;
  }

  .submission-info-row {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
} 