.class-details-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  margin-left: 250px;
  width: calc(100% - 250px);
  background: transparent;
}

.class-header {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #f0f0f0;
}

.class-header h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.class-name {
  font-size: 2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.teacher-info {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.teacher-info .info-label {
  font-weight: 500;
}

.teacher-info .info-value {
  color: #444;
}

.questions-overview {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #333;
}

.class-title {
  text-align: center;
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 600;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.time-display {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  font-weight: 500;
}

.class-info {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.info-line {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
}

.question-line {
  padding: 0.75rem;
  background: #f8f9fa;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.question-line span:first-child {
  color: #333;
  font-size: 0.95rem;
}

.question-line span:last-child {
  color: #333;
  font-size: 0.95rem;
  margin-left: auto;
  padding-left: 7rem;
}

.teacher-name {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.total-questions {
  font-size: 1rem;
  color: #666;
}

.marks-breakdown {
  margin: 1.5rem 0;
}

.mark-item {
  background: #f8f9fa;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-text {
  color: #333;
  font-size: 0.95rem;
}

.marks-value {
  color: #333;
  font-size: 0.95rem;
  padding-left: 1rem;
  border-left: 1px solid #dee2e6;
}

.action-section {
  margin-top: 2rem;
  text-align: center;
}

.start-submission-button {
  background-color: #FFD700;  /* Gold color */
  color: #333;  /* Dark text for contrast */
  border: none;
  padding: 0.75rem 2rem;
  font-size: 1.1rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  font-weight: 500;
}

.start-submission-button:hover {
  background-color: #FFC000;  /* Slightly darker gold on hover */
}

.start-submission-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  font-size: 1.1rem;
}

.error {
  color: #dc3545;
}

.loading {
  color: #666;
}

.class-summary {
  margin-bottom: 2rem;
}

.class-summary h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .class-details-container {
    margin-left: 0;
    width: 100%;
    padding: 1rem;
  }

  .class-summary {
    grid-template-columns: 1fr;
  }

  .summary-card {
    padding: 1rem;
  }

  .summary-number {
    font-size: 2rem;
  }
} 