.cursor-pointer {
  cursor: pointer;
}

.table th {
  background-color: #f8f9fa;
  padding: 1rem;
}

.table th:hover {
  background-color: #e9ecef;
}

.table td {
  padding: 0.75rem;
}

.badge {
  font-size: 0.875rem;
  padding: 0.5em 0.75em;
  text-transform: capitalize;
}

/* Custom colors for Bootstrap table contextual classes */
.table-success {
  --bs-table-bg: rgba(144, 238, 144, 0.2);
  --bs-table-striped-bg: rgba(144, 238, 144, 0.25);
  --bs-table-hover-bg: rgba(144, 238, 144, 0.3);
}

.table-info {
  --bs-table-bg: rgba(168, 139, 250, 0.2);
  --bs-table-striped-bg: rgba(168, 139, 250, 0.25);
  --bs-table-hover-bg: rgba(168, 139, 250, 0.3);
}

@media (max-width: 768px) {
  .table {
    font-size: 0.875rem;
  }
  
  .table th, .table td {
    padding: 0.5rem;
  }
  
  .badge {
    font-size: 0.75rem;
  }
}

.user-name-link {
  color: #2c3e50;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.2s ease;
  font-weight: 500;
}

.user-name-link:hover {
  color: #321E3C;
  text-decoration: underline;
}

.email-link {
  color: #2c3e50;
  text-decoration: none;
  transition: color 0.2s ease;
}

.email-link:hover {
  color: #321E3C;
  text-decoration: underline;
}

.page-header {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.page-header h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin: 0;
} 