.submissions-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.page-title {
  text-align: center;
  color: #333;
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
}

.submissions-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.submission-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: all 0.2s ease;
}

.submission-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.submission-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.submission-header h3 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
}

.submission-date {
  color: #666;
  font-size: 0.9rem;
}

.submission-details {
  display: flex;
  justify-content: flex-end;
}

.score-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.score-label {
  color: #666;
  font-size: 1rem;
}

.score-value {
  color: #0066cc;
  font-weight: 600;
  font-size: 1rem;
}

.loading-message {
  text-align: center;
  color: #666;
  font-style: italic;
  margin: 2rem 0;
}

.error-message {
  background-color: #fff3f3;
  color: #dc3545;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
  margin: 2rem 0;
}

.no-submissions {
  text-align: center;
  color: #666;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 8px;
  font-style: italic;
} 