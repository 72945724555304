.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0px;
}

.pagination button {
  width: 40px;
  height: 40px;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #eaf9ff;
  color: black;
  border: 1.5px solid #6dc5eb;
}

.pagination button.active {
  font-weight: 900;
  border-color: #0e93cd;
  background: #0e93cd;
  color: white;
}
