.create-class-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.create-class-header {
  margin-bottom: 2rem;
}

.create-class-header h1 {
  color: #333;
  font-size: 2rem;
  margin: 0;
}

.create-class-content {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Steps Indicator */
.steps-indicator {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 0 1rem;
  position: relative;
}

.steps-indicator::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #dee2e6;
  z-index: 1;
}

.step {
  flex: 1;
  text-align: center;
  padding: 1rem;
  position: relative;
  color: #6c757d;
  font-weight: 500;
  background: white;
  z-index: 2;
}

.step.active {
  color: #b39824;
}

.step.completed {
  color: #b39824;
}

.steps-indicator .step:first-child {
  padding-left: 0;
}

.steps-indicator .step:last-child {
  padding-right: 0;
}

/* Step Content */
.step-content {
  padding: 1rem 0;
}

.step-content h2 {
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 2rem;
}

.input-container input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
}

/* Table Styles */
.table-responsive {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.table {
  margin-bottom: 0;
}

.table th {
  border-top: none;
  background-color: #fff;
  font-weight: 600;
}

.table td {
  vertical-align: middle;
}

.table-hover tbody tr:hover {
  background-color: rgba(230, 197, 46, 0.1);
}

.table-active {
  background-color: rgba(230, 197, 46, 0.2) !important;
}

.form-check {
  margin: 0;
  display: flex;
  justify-content: center;
}

.form-check-input {
  cursor: pointer;
  appearance: none;
  background-color: #fff;
  border: 1px solid #ddd;
  width: 1.15em;
  height: 1.15em;
}

.form-check-input:checked {
  background-color: #b39824;
  border-color: #b39824;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

/* Button Styles */
.step-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.back-btn {
  background-color: #f8f9fa !important;
  border: 1px solid #ddd !important;
  color: #333 !important;
}

.back-btn:hover {
  background-color: #e9ecef !important;
}

.next-btn,
.create-btn {
  background-color: #e6c52e !important;
  border-color: #e6c52e !important;
  color: #000 !important;
  font-weight: 500;
  min-width: 100px;
}

.next-btn:hover,
.create-btn:hover {
  background-color: #b89d27 !important;
  border-color: #b89d27 !important;
}

.next-btn:disabled,
.create-btn:disabled {
  background-color: #e9ecef !important;
  border-color: #ddd !important;
  color: #6c757d !important;
}

@media (max-width: 768px) {
  .input-container {
    flex-direction: column;
  }
  
  .input-container input {
    width: 100%;
  }
  
  .steps-indicator {
    flex-direction: column;
    gap: 1rem;
  }
  
  .step:not(:last-child)::after {
    display: none;
  }
  
  .step-actions {
    flex-direction: column;
    gap: 1rem;
  }
  
  .back-btn,
  .next-btn,
  .create-btn {
    width: 100%;
  }
} 