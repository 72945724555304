.center-form {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  padding-top: 300px;
  position: relative;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

h2 {
  color: #444;
  margin-bottom: 1.5rem;
}

h3 {
  color: #555;
  margin-bottom: 1rem;
}

.error-message {
  background-color: #fff3f3;
  color: #dc3545;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.loading-message {
  text-align: center;
  color: #666;
  margin: 2rem 0;
  font-style: italic;
}

.class-selection {
  margin: 2rem 0;
}

.class-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  padding: 1rem 0;
}

.class-box {
  background-color: white;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.class-box:hover {
  transform: translateY(-2px);
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.class-box h3 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
  font-weight: 500;
}

.class-header {
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;
  background: white;
  z-index: 100;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.class-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
  font-weight: 500;
}

.back-button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
}

.questions-container {
  margin-top: 1rem;
  position: relative;
  z-index: 1;
}

.questions-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.question-item {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

.question-header {
  background: #f8f9fa;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.question-header span {
  color: #333;
  font-size: 1rem;
}

.question-header .marks {
  color: #666;
}

.question-content {
  padding: 1rem;
  background: white;
}

.question-content p {
  margin: 0;
  color: #333;
  font-size: 1rem;
  line-height: 1.5;
}

.question-details {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 0.9rem;
  color: #666;
}

.mark-range {
  color: #666;
}

.no-questions {
  text-align: center;
  color: #666;
  font-style: italic;
  margin: 2rem 0;
}

.next-button {
  margin-top: 2rem;
  width: 100%;
  max-width: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .center-form {
    padding: 1rem;
    padding-top: 340px;
  }

  .class-header {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    top: 80px;
  }

  .class-grid {
    grid-template-columns: 1fr;
  }

  .question-details {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .back-button {
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .class-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.question-container {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 2rem;
  margin: 2rem auto;
  max-width: 800px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 1rem;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.nav-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled) {
  background-color: #0056b3;
  transform: translateY(-1px);
}

.question-progress {
  text-align: center;
  color: #666;
  margin: 1rem 0;
  font-size: 0.9rem;
}

.delete-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #c82333;
}

.question-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
}

.question-header {
  color: #666;
  margin-bottom: 15px;
  font-size: 1rem;
}

.question-content {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 15px;
  color: #333;
}

.marks-info {
  color: #666;
  margin-bottom: 20px;
  font-size: 0.9rem;
}

.answer-input {
  width: 100%;
  min-height: 200px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 20px 0;
  font-size: 1rem;
  line-height: 1.5;
  resize: vertical;
}

.answer-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.answer-input.required {
  border-color: #dc3545;
}

.navigation-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.question-counter {
  color: #666;
}

.error-message {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 5px;
}

.submission-container {
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

.submission-header {
  margin-bottom: 1.5rem;
  text-align: center;
}

.submission-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
}

.teacher-name {
  color: #666;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.question-container {
  background-color: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.questions-overview {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.questions-overview h3 {
  margin: 0 0 1rem 0;
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #dee2e6;
}

.questions-overview div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  color: #495057;
  font-size: 0.95rem;
}

.questions-overview .value {
  font-weight: 600;
  color: #0066cc;
}

.question-header {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.question-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.marks {
  color: #666;
  font-size: 1rem;
}

.question-content {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.question-content p {
  margin: 0;
  font-size: 1.1rem;
  color: #333;
}

.answer-label {
  font-size: 1.1rem;
  color: #333;
  font-weight: 500;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
}

.input-options {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 0 0.5rem;
}

.input-option {
  flex: 1;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 2px solid #e9ecef;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 1rem;
  text-align: center;
}

.input-option:hover {
  transform: translateY(-2px);
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.input-option i {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  color: #007bff;
}

.input-option span {
  font-size: 0.85rem;
  color: #495057;
}

.answer-section {
  display: none;
}

.answer-section.visible {
  display: block;
  margin-top: 2rem;
}

textarea {
  width: 100%;
  min-height: 200px;
  padding: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.5;
  resize: vertical;
}

textarea:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.1);
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.nav-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-button.primary {
  background-color: #FFD700;
  color: #333;
}

.nav-button.primary:hover:not(:disabled) {
  background-color: #FFC000;
}

.error-message {
  color: #dc3545;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.processing-message {
  text-align: center;
  color: #666;
  margin: 1rem 0;
  font-style: italic;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .submission-header {
    top: 50px;
    padding: 0.75rem;
  }
  
  .submission-container {
    padding-top: 120px;
  }

  .submission-header h2 {
    font-size: 1.25rem;
  }

  .question-container {
    margin-top: 1rem;
    padding: 0.75rem;
  }

  .input-options {
    padding: 0;
  }

  .input-option {
    height: 90px;
    padding: 0.75rem;
  }

  .input-option i {
    font-size: 1.5rem;
  }

  .input-option span {
    font-size: 0.75rem;
  }

  .nav-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

.question-box {
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.question-title {
  background-color: #f8f9fa;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.question-title span {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

.question-title .marks-value {
  color: #0066cc;
  font-weight: 600;
}

.question-content {
  padding: 1rem;
  background-color: white;
}

.question-content p {
  margin: 0;
  font-size: 1rem;
  color: #333;
  line-height: 1.5;
}

.total-info {
  text-align: right;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.class-name-header {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: white;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  z-index: 100;
}

.class-name-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
}

.submission-container {
  padding-top: 120px;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Submission Complete Styles */
.results-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.total-score-section {
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.total-score-section h3 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}

.question-result {
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.question-result .question-header {
  background: #f8f9fa;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
}

.question-result .question-header h4 {
  margin: 0;
  color: #333;
}

.question-result .score {
  font-weight: 600;
  color: #0066cc;
}

.answer-section, .feedback-section {
  padding: 1rem;
}

.answer-section {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
}

.feedback-section {
  background: #f8f9fa;
}

.answer, .feedback, .suggestions {
  margin-bottom: 1rem;
}

.answer:last-child, .feedback:last-child, .suggestions:last-child {
  margin-bottom: 0;
}

.answer strong, .feedback strong, .suggestions strong {
  display: block;
  margin-bottom: 0.5rem;
  color: #495057;
}

.answer p, .feedback p, .suggestions p {
  margin: 0;
  color: #333;
  line-height: 1.5;
}

.grading-pending {
  text-align: center;
  padding: 2rem;
}

.alert {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.alert-info {
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  color: #004085;
}

.mt-4 {
  margin-top: 1.5rem;
}

.back-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.back-button:hover {
  background-color: #0056b3;
}

.submission-success {
  text-align: center;
  color: #28a745;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  border-radius: 6px;
  animation: fadeIn 0.3s ease-in;
  position: relative;
  z-index: 1000;
}

/* Hide any default success messages */
.submission-saved-message,
div[role="alert"] {
  display: none !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.submission-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.submission-feedback {
  background: #fff;
  padding: 1.5rem 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  color: #333;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}