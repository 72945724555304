.link-style {
  text-decoration: none;
  color: black;
  .bar-container {
    min-width: 330px;
    background-color: #eaf9ff;
    border-radius: 5px;
    border: 2px solid #6dc5eb;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bar-container-left {
      display: flex;
      align-items: center;
      padding-left: 10px;

      .img-container {
        height: inherit;
        padding-bottom: 6px;
        img {
          width: 40px;
          border-radius: 100%;
          border: 1px solid #0e93cd;
        }
      }

      span {
        padding-left: 15px;
      }
    }

    .bar-container-right {
      span {
        padding-right: 30px;
      }
    }
  }
}
