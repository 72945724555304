.layout {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  /*margin-left: 250px;*/
  padding: 1.5rem;
  background-color: #f5f5f5;

}

.dashboard-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-right: 200px;

}

.dashboard-header {
  margin-bottom: 1.5rem;
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-header h1 {
  font-size: 1.75rem;
  font-weight: 700;
  color: #333;
  margin: 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  margin-bottom: 1.5rem;
}

.stat-card {
  background: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  border: 2px solid transparent;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 140px;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-color: #f2d13e;
}

.stat-card:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stat-card h3 {
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
  text-align: center;
}

.stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin: 0;
}

.stat-card .text-muted {
  display: block;
  margin-top: 1rem;
  font-size: 0.875rem;
  color: #6c757d;
  text-align: center;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e6c52e;
}

.section-header h2 {
  color: #333;
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}

.view-all-btn {
  background: none;
  border: none;
  color: #e6c52e;
  font-weight: 500;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.view-all-btn:hover {
  background: rgba(230, 197, 46, 0.1);
}

.empty-state {
  text-align: center;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 6px;
}

.empty-state p {
  color: #666;
  margin: 0;
}

.admin-actions {
  display: none;
}

.create-class-section,
.view-classes-section {
  margin-top: 2rem;
}

.input-container {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.input-container input {
  flex: 1;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.list-container {
  margin-bottom: 2rem;
}

.list-container h2 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
}

.list-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-container li {
  padding: 1rem;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
}

.list-container li:hover {
  background: #f5f5f5;
}

.list-container li.selected {
  background: #f2d13e;
  color: #000;
}

.class-details {
  margin-top: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.class-details h3 {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
}

.class-details p {
  margin-bottom: 0.5rem;
}

.success-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.success-content {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.success-content p {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}

.recent-activity {
  margin-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;
}

.recent-section {
  background: white;
  border-radius: 8px;
  padding: 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.recent-section h2 {
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e6c52e;
}

.recent-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.recent-item {
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.recent-item:hover {
  background: #f0f0f0;
  transform: translateY(-2px);
}

.recent-item h3 {
  color: #333;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.recent-item p {
  color: #666;
  font-size: 0.9rem;
  margin: 0.25rem 0;
}

@media (max-width: 1200px) {
  .main-content {
    padding: 1rem;
  }
  
  .stats-grid {
    gap: 1rem;
  }
  
  .recent-activity {
    gap: 1rem;
  }
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    padding: 1rem;
  }

  .stats-grid {
    grid-template-columns: 1fr;
  }

  .recent-activity {
    grid-template-columns: 1fr;
  }

  .action-buttons {
    flex-direction: column;
  }

  .input-container {
    flex-direction: column;
  }

  .admin-actions {
    padding: 1.5rem;
  }
}