.submission-complete {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
}

.submission-complete h1 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

.total-score-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  margin-bottom: 3rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.total-score-card h2 {
  color: #495057;
  margin-bottom: 1rem;
}

.total-score-card .score {
  font-size: 2.5rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 0.5rem;
}

.total-score-card .percentage {
  font-size: 1.5rem;
  color: #6c757d;
}

.questions-breakdown {
  margin-top: 3rem;
}

.questions-breakdown h2 {
  color: #495057;
  margin-bottom: 2rem;
}

.question-result {
  background-color: white;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.question-result h3 {
  color: #007bff;
  margin-bottom: 1rem;
}

.question-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #212529;
}

.answer-section {
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;
}

.answer-section h4 {
  color: #495057;
  margin-bottom: 0.5rem;
}

.answer-text {
  white-space: pre-wrap;
  line-height: 1.6;
}

.score-section {
  border-top: 1px solid #dee2e6;
  padding-top: 1rem;
}

.question-score {
  font-size: 1.2rem;
  font-weight: bold;
  color: #007bff;
  margin-bottom: 1rem;
}

.feedback {
  background-color: #e9ecef;
  padding: 1rem;
  border-radius: 4px;
}

.feedback h4 {
  color: #495057;
  margin-bottom: 0.5rem;
}

.feedback p {
  color: #6c757d;
  line-height: 1.6;
  margin: 0;
}

.back-button {
  display: block;
  width: 200px;
  margin: 3rem auto 0;
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
}

.back-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .submission-complete {
    padding: 1rem;
  }
  
  .total-score-card .score {
    font-size: 2rem;
  }
  
  .total-score-card .percentage {
    font-size: 1.2rem;
  }
} 