.signup-container {
  display: flex;
  min-height: 100vh;
}

.left-section {
  flex: 1;
  background-color: #f2d13e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.logo {
  width: 400px;
  height: auto;
}

.right-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #ffffff;
}

.signup-form-container {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-section {
  text-align: center;
  margin-bottom: 2.5rem;
  width: 100%;
}

.signup-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #000000;
  text-align: center;
  line-height: 1.2;
}

.subtitle {
  color: #666666;
  font-size: 16px;
  text-align: center;
  margin: 0;
  line-height: 1.5;
}

form {
  width: 100%;
  margin-top: 1rem;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #000000;
}

.password-input-container {
  position: relative;
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #666666;
}

.password-toggle:hover {
  color: #bea431;
}

.password-toggle:focus {
  outline: none;
}

.form-control {
  width: 100%;
  padding: 12px;
  padding-right: 40px;
  border: 1px solid #000000;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.1em;
  text-align: left;
}

.form-control[type="password"] {
  letter-spacing: 0.2em;
  text-align: left;
}

.form-control[type="text"] {
  text-align: left;
}

.form-control::placeholder {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.form-control:focus {
  outline: none;
  border-color: #000000;
  border-width: 2px;
}

.form-control.error {
  border-color: #DC2626;
}

.helper-text {
  font-size: 12px;
  color: #666666;
  margin-top: 4px;
  margin-bottom: 0;
}

.error-text {
  font-size: 12px;
  color: #DC2626;
  margin-top: 4px;
  margin-bottom: 0;
}

.success-text {
  font-size: 14px;
  color: #059669;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 500;
}

.error-message {
  background-color: #FEE2E2;
  color: #DC2626;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 24px;
  font-size: 14px;
}

.create-account-button {
  width: 100%;
  padding: 12px;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-account-button:hover:not(:disabled) {
  background-color: #bea431;
}

.create-account-button:disabled {
  background-color: #D1D5DB;
  cursor: not-allowed;
}

.login-prompt {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
  color: #666666;
}

.login-link {
  color: #bea431;
  text-decoration: none;
  font-weight: 500;
}

.login-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .signup-container {
    flex-direction: column;
  }
  
  .left-section {
    min-height: 200px;
  }
  
  .right-section {
    padding: 2rem 1rem;
  }

  .name-row {
    flex-direction: column;
    gap: 24px;
  }
}

.school-code-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.school-code-input {
  width: 70px;
  height: 40px;
  padding: 8px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-family: monospace;
  letter-spacing: 2px;
}

.school-code-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.school-code-input.error {
  border-color: #dc3545;
}

.school-code-input.error:focus {
  box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.25);
}

.school-code-separator {
  color: #666;
  font-size: 20px;
  font-weight: bold;
  user-select: none;
}

.logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.logo {
  width: 400px;
  height: auto;
}

.school-logo {
  width: 200px !important;
  height: auto !important;
  object-fit: contain;
}