.submission-details-container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.class-name-header {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  background: white;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  z-index: 100;
}

.class-name-header h2 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
}

.submission-container {
  padding-top: 120px;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.submission-info-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.submission-meta {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.meta-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.meta-label {
  color: #666;
  font-weight: 500;
}

.meta-value {
  color: #333;
  font-weight: 600;
}

.meta-value.status {
  color: #0066cc;
  text-transform: capitalize;
}

.meta-value.score {
  color: #28a745;
}

.question-card {
  background: white;
  border-radius: 8px;
  margin-bottom: 1rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.question-header {
  margin-bottom: 0.5rem;
}

.question-header h3 {
  margin: 0;
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
}

.question-text {
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  white-space: pre-wrap;
  word-break: break-word;
}

.marks-text {
  color: #666;
  font-size: 1rem;
  font-weight: 500;
  padding-top: 0.5rem;
}

.expand-arrow {
  color: #666;
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.expand-arrow.expanded {
  transform: rotate(180deg);
}

.question-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.question-score {
  color: #0066cc;
  font-weight: 600;
  background: rgba(0, 102, 204, 0.1);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
}

.answer-section {
  margin: 1rem 0;
  padding: 1.5rem;
  background: #fff;
  border: 1px solid #FFD700;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.answer-label {
  color: #333;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #FFD700;
}

.answer-text {
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;
  white-space: pre-wrap;
  padding: 1rem;
  background: #fffdf0;
  border: 1px solid #FFD700;
  border-radius: 8px;
  margin-top: 0.5rem;
}

.score-section {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
  padding: 0.75rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.score-label {
  color: #666;
  font-weight: 500;
}

.score-value {
  color: #28a745;
  font-weight: 600;
  font-size: 1.1rem;
}

.total-marks {
  color: #666;
  font-weight: 500;
}

.feedback-section {
  margin-top: 1rem;
  padding: 1rem;
  background: #fff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  background-color: #f8f9fa;
}

.feedback-label {
  color: #495057;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #dee2e6;
}

.feedback-text {
  color: #333;
  line-height: 1.6;
  font-size: 1rem;
  white-space: pre-wrap;
  padding: 0.5rem;
}

.back-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 2rem auto;
  padding: 0.75rem 1.5rem;
  background-color: #FFD700;
  color: #333;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: #FFC000;
  transform: translateY(-1px);
}

.loading-message, .error-message, .no-data-message {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error-message {
  color: #dc3545;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .class-name-header {
    top: 50px;
    padding: 0.75rem;
  }
  
  .submission-container {
    padding-top: 100px;
  }

  .class-name-header h2 {
    font-size: 1.25rem;
  }

  .meta-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }

  .question-card {
    padding: 1rem;
  }
  
  .question-text {
    padding: 0.75rem;
    font-size: 1rem;
  }
} 