.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #f2d13e;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  overflow: hidden;
}

.sidebar-header {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
}

.sidebar .logo {
  width: 240px !important;
  height: auto !important;
  object-fit: contain;
}

.sidebar .school-logo {
  width: 50px !important;
  height: auto !important;
  object-fit: scale-down !important;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  max-width: 50px !important;
  max-height: none !important;
  display: block !important;
}

.user-badge {
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  text-transform: capitalize;
  margin: 8px 0;
}

.welcome-text {
  font-size: 1rem;
  color: #000;
  text-align: center;
  margin-top: 0.5rem;
}

.sidebar-nav {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  color: #000;
  text-decoration: none;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  cursor: pointer;
  font-size: 1rem;
}

.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.nav-item.active {
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 500;
}

.nav-icon {
  font-size: 1.25rem;
  min-width: 24px;
  color: #000000;
}

.sidebar-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.logout-btn {
  width: 100%;
  background: none;
  border: none;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  text-align: left;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.5rem;
}

.logout-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    width: 100%;
    height: 60px;
    padding: 0 0.5rem;
    flex-direction: row;
    align-items: center;
    background-color: #f2d13e;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .sidebar-header {
    display: none;
  }

  .sidebar-nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    gap: 0.5rem;
  }

  /* Base styles for nav items */
  .nav-item {
    flex: none;
    min-width: auto;
    padding: 0.5rem;
    justify-content: center;
    margin: 0;
    color: #000000;
  }

  .nav-item span {
    display: none;
  }

  .nav-icon {
    font-size: 1.5rem;
    margin: 0;
    color: #000000;
  }

  /* Make logout icon red */
  .nav-item:last-child .nav-icon {
    color: #ff0000;
  }

  /* Remove all extra spacing */
  .sidebar > *,
  .nav-item,
  .sidebar-nav > * {
    margin: 0;
  }

  /* Add background to active items */
  .nav-item.active {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
}

.role-badge {
  margin-top: 0.5rem;
}

.role-badge .badge {
  font-size: 0.9rem;
  padding: 0.35rem 0.65rem;
}

/* Role-specific colors */
.role-badge .badge.student {
  background-color: #4ade80;
}

.role-badge .badge.teacher {
  background-color: #60a5fa;
}

.role-badge .badge.admin {
  background-color: #f87171;
}

.sidebar-footer button.nav-item {
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  color: #6c757d;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}

.sidebar-footer button.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #007bff;
}

.sidebar-footer button.nav-item .nav-icon {
  margin-right: 12px;
  font-size: 1.2em;
} 