.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 4rem);
  padding: 2rem;
}

.coming-soon-content {
  text-align: center;
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.coming-soon-content h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.coming-soon-badge {
  display: inline-block;
  background: #f2d13e;
  color: #000;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  font-size: 1.2rem;
}

.coming-soon-content p {
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 1rem;
}

.coming-soon-content .subtitle {
  color: #666;
  font-size: 1rem;
} 