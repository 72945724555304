.class-details-container {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
}

.class-name {
  font-size: 1.8rem;
  color: #333;
  margin: 0 0 2rem 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.teacher-info {
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
}

.teacher-info h2 {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
}

.teacher-info p {
  font-size: 1rem;
  color: #333;
  margin: 0;
}

.questions-section {
  margin-top: 2rem;
}

.questions-section h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
}

.questions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  gap: 1rem;
}

.question-item {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1.5rem;
}

.question-item h3 {
  font-size: 1.1rem;
  color: #333;
  margin: 0 0 1rem 0;
}

.question-details {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
  color: #666;
  font-size: 0.9rem;
}

.rubric {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.rubric h4 {
  font-size: 0.9rem;
  color: #333;
  margin: 0 0 0.5rem 0;
}

.rubric p {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
  line-height: 1.4;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #dc3545;
}

@media (max-width: 768px) {
  .class-content {
    grid-template-columns: 1fr;
  }
  
  .class-header {
    padding: 1rem;
  }
  
  .class-name {
    font-size: 1.5rem;
  }
} 