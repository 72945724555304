.login-container {
  display: flex;
  min-height: 100vh;
}

.left-section {
  flex: 1;
  background-color: #f2d13e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  max-width: 400px;
  height: auto;
  width: 80%;
}

.right-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #ffffff;
}

.login-form-container {
  width: 100%;
  max-width: 400px;
  padding: 0 1rem;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #000000;
}

.subtitle {
  color: #666666;
  margin-bottom: 32px;
  font-size: 14px;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #000000;
}

.password-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.forgot-password {
  color: #bea431;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password:hover {
  text-decoration: underline;
}

.password-input-container {
  position: relative;
  width: 100%;
}

.password-toggle {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  color: #666666;
}

.password-toggle:hover {
  color: #bea431;
}

.password-toggle:focus {
  outline: none;
}

.form-control {
  width: 100%;
  padding: 12px;
  padding-right: 40px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  font-size: 14px;
  transition: border-color 0.2s;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.1em;
  text-align: left;
}

.form-control[type="password"] {
  letter-spacing: 0.2em;
  text-align: left;
}

.form-control[type="text"] {
  text-align: left;
}

.form-control::placeholder {
  letter-spacing: normal;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.form-control:focus {
  outline: none;
  border-color: #F7D44C;
}

.error-message {
  background-color: #FEE2E2;
  color: #DC2626;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 24px;
  font-size: 14px;
}

.sign-in-button {
  width: 100%;
  padding: 12px;
  background-color: #000000;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.sign-in-button:hover {
  background-color: #bea431;
}

.signup-prompt {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
  color: #666666;
}

.signup-link {
  color: #bea431;
  text-decoration: none;
  font-weight: 500;
}

.signup-link:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }
  
  .left-section {
    min-height: 200px;
  }
  
  .right-section {
    padding: 2rem 1rem;
  }
}
